<template>
    <main class="main">
        <section class="left">
            <div class="login_outer flex flex-col">
                <div>
                    <h1 class="text-xl font-bold">重設忘記密碼</h1>
                </div>
                <div class="flex flex-col my-4">
                    <h1 class="title">密碼 *</h1>
                    <ValidationProvider
                        tag="div"
                        class="relative"
                        mode="aggressive"
                        name="密碼"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <vs-input placeholder="必填" v-model="pawd" type="password" />
                        <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                </div>
                <div class="flex flex-col my-4">
                    <h1 class="title">確認密碼 *</h1>
                    <ValidationProvider
                        tag="div"
                        class="relative"
                        mode="aggressive"
                        name="確認密碼"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <vs-input placeholder="必填" v-model="chkpawd" type="password" />
                        <errorDiv>{{ errors[0] }}</errorDiv>
                    </ValidationProvider>
                </div>
                <div class="pt-5">
                    <vs-button @click="uAccountPawd"> 確定修改 </vs-button>
                </div>
            </div>
        </section>

        <section class="right"></section>
    </main>
</template>

<script>
export default {
    data() {
        return {
            pawd: '',
            chkpawd: '',
        }
    },
    methods: {
        uAccountPawd() {
            this.$axios({
                url: 'front/set/slogin/uAccountPawd.php',
                headers: {
                    pawd: this.pawd,
                },
                kcval: true,
                data: {
                    lang: 0,
                    kdata: this.$route.query.kdata,
                },
            }).then((res) => {
                if (res.data.Success) {
                    this.pawd = ''
                    this.chkpawd = ''
                    this.$myVS.openNoti(res.data.Message, '', undefined)
                } else {
                    this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.main {
    @apply flex flex-col lg:flex-row h-screen text-gray-text_dark;
}

.left {
    @apply h-full relative flex-shrink-0 flex-grow
  flex items-center justify-center;

    background: url('../assets/images/login/bg1.jpg') center/cover;

    @screen lg {
        min-width: 500px;
        background: white;
    }
}

.login_outer {
    @apply bg-white
    rounded-2xl lg:absolute 
    p-6 shadow-2xl
    flex items-center justify-center
    lg:top-1/2 lg:-right-3/4 lg:transform lg:-translate-y-1/2
    w-full h-auto m-3 sm:m-0;

    background: rgba(255, 255, 255, 1);

    @screen sm {
        width: 600px;
        height: 300px;
    }
}

.right {
    @apply h-full w-full lg:w-16/20 hidden lg:block;
    background: url('../assets/images/login/bg1.jpg') center/cover;
}
</style>
